/*.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: rgba(26, 44, 74, 0.8) !important;
    --bs-btn-border-color: rgba(26, 44, 74, 0.8) !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}
*/

.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
 /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button.btn.btn-success {
float: right;
}
button.btn.btn-secondary {
float: right;
margin-right: 15px;
}

.logoArea { margin-bottom: 40px; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
